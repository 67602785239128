import * as React from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../../BasePage"
import {mobile} from "../../../App"
import {Redirect} from "react-router-dom"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    redirectTo?: string
}

export default class OngoingPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }


    render() {
        const {redirectTo} = this.state;
        const buttonWidth = mobile ? "80vw" : "13rem";
        const fontSize = mobile ? "3rem" : "1rem";
        const {groupplayId} = this.props.match.params;
        if (redirectTo) {
            return <Redirect to={redirectTo}/>
        }
        return (
            <BasePage {...this.props} center={true} adminPage={true} loading={false} fatalError={false}>
                <span style={{marginBottom: "1rem", fontSize: fontSize}}>I nuvarande (pågående) omgång:</span>
                <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                        onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/removePlayerOngoingRound"})}>
                    Ta bort spelare
                </button>
                <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                        onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/addPlayerOngoingRound"})}>
                    Lägg till spelare
                </button>
            </BasePage>
        );
    }
}
