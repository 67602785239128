import React from 'react'
import {HashRouter, Route, RouteComponentProps, Switch} from 'react-router-dom'
import './App.css'
import LoginPage from "./pages/account-related/LoginPage"
import StartPage from "./pages/start/StartPage"
import NewAccountPage from "./pages/account-related/NewAccountPage"
import ResetPasswordPage from "./pages/account-related/ResetPasswordPage"
import ForgotPasswordPage from "./pages/account-related/ForgotPasswordPage"
import StandingsPage from "./pages/standings/StandingsPage"
import SchedulePage from "./pages/schedule/SchedulePage"
import {SessionDto} from "./api/dtos"
import EditPersonalPrefsPage from "./pages/account-related/EditPersonalPrefsPage"
import TestPage from "./pages/TestPage"
import AdminPlayersPage from "./pages/admin/players/AdminPlayersPage"
import AdminBookingsPage from "./pages/admin/bookings/AdminBookingsPage"
import AdminNewSchedulePage from "./pages/admin/schedule/AdminNewSchedulePage"
import {touchSession} from "./api/api"
import VerifyMailPage from "./pages/account-related/VerifyMailPage"
import FullPageMessage from "./components/FullPageMessage"
import FullPageSpinner from "./components/FullPageSpinner"
import ReportResultPage from "./pages/match/ReportResultPage"
import EditTimeRatingsPage from "./pages/account-related/EditTimeRatingsPage"
import ChangePasswordPage from "./pages/account-related/ChangePasswordPage"
import MatchPage from "./pages/match/MatchPage"
import EditCantPlayDatesPage from "./pages/account-related/EditCantPlayDatesPage"
import {AddPlayerPage} from "./pages/admin/players/AddPlayerPage"
import SignupPage from "./pages/signup/SignupPage"
import InvitePage from "./pages/account-related/InvitePage"
import SettingsPage from "./pages/admin/settings/SettingsPage"
import BookingsSystemSettingsPage from "./pages/admin/settings/BookingSystemSettingsPage"
import BookingSettingsPage from "./pages/admin/settings/BookingSettingsPage"
import ContactsPage from "./pages/contacts/ContactsPage"
import HistoryPage from "./pages/history/HistoryPage"
import HistoryStandingsPage from "./pages/history/HistoryStandingsPage"
import HistorySchedulePage from "./pages/history/HistorySchedulePage"
import RulesPage from "./pages/rules/RulesPage"
import RulesTextConfigPage from "./pages/admin/settings/RulesTextConfigPage"
import RulesConfigPage from "./pages/admin/settings/RulesConfigPage"
import GroupplaySettingsPage from "./pages/admin/settings/GroupplaySettingsPage"
import HistoryBillingPage from "./pages/history/HistoryBillingPage";
import {SendSignupsPage} from "./pages/admin/players/SendSignupsPage";
import SignupLinkPage from "./pages/signup/SignupLinkPage";
import OngoingPage from "./pages/admin/ongoing/OngoingPage";
import RemovePlayerPage from "./pages/admin/ongoing/RemovePlayerPage";

interface State {
    session?: SessionDto
    pagePath: string
    fatalError: boolean
    loading: boolean
}

//ugly global variable! But seems to work fine...
export var mobile: boolean;

class App extends React.Component<RouteComponentProps, State> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            session: undefined,
            pagePath: '/',
            fatalError: false,
            loading: true,
        };
        mobile = (window.innerWidth < 600);
        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.reportCurrentPagePath = this.reportCurrentPagePath.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        const newMobile = (window.innerWidth < 600);
        if (mobile !== newMobile) {
            console.log('mobile mode changed: ' + newMobile);
            mobile = newMobile;
            //force re-render so all components re-renders with the new mobile state
            this.forceUpdate();
        }
    };

    async componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        const sessionId = localStorage.getItem('sessionId');
        if (sessionId !== null) {
            console.log('sessionId found in localStorage!');
            try {
                const session: SessionDto = await touchSession(sessionId);
                this.setState({session: session});
            } catch (error:any) {
                switch (error.type) {
                    case "INVALID_SESSION":
                        //fine just ignore and continue without session
                        console.log('The sessionId was not valid in backend..' + error.type);
                        break;
                    default:
                        this.setState({fatalError: true})
                }
            }
        }
        this.setState({loading: false})
    }

    handleSessionChange(rememberMe: boolean, session?: SessionDto) {
        if (session) {
            console.log('User logged in! name=' + session.firstName + ' ' + session.lastName);
        } else {
            console.log('User logged out!')
        }
        this.setState({session: session});
        if (rememberMe && session) {
            localStorage.setItem("sessionId", session.sessionId);

        }
        if (!rememberMe || !session) {
            localStorage.removeItem('sessionId')
        }
    }

    reportCurrentPagePath(pagePath: string) {
        if (pagePath !== '/login' &&
            pagePath !== '/editPersonalPrefs' &&
            pagePath !== '/changePassword' &&
            pagePath !== '/editTimeRatings' &&
            pagePath !== '/editCantPlayDates' &&
            pagePath !== '/newAccount' &&
            pagePath !== '/forgotPassword') {
            console.log('updating pagePath:' + pagePath);
            this.setState({pagePath: pagePath});
        }
    }

    render() {
        if (this.state.loading) {
            return (<FullPageSpinner/>);
        }
        if (this.state.fatalError) {
            return (<FullPageMessage text='Något gick fel... Ladda om sidan och försök igen om ett litet tag!'/>);
        }
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/"
                           render={(props) => <StartPage {...props}
                                                         pagePath={this.reportCurrentPagePath}
                                                         onSessionChange={this.handleSessionChange}
                                                         session={this.state.session}/>}/>
                    <Route path="/login"
                           render={(props) => <LoginPage {...props}
                                                         redirectTo={this.state.pagePath}
                                                         pagePath={this.reportCurrentPagePath}
                                                         disableHeader={true}
                                                         onSessionChange={this.handleSessionChange}/>}/>
                    <Route path="/editPersonalPrefs"
                           render={(props) => <EditPersonalPrefsPage {...props}
                                                                     redirectTo={this.state.pagePath}
                                                                     pagePath={this.reportCurrentPagePath}
                                                                     onSessionChange={this.handleSessionChange}
                                                                     session={this.state.session}/>}/>

                    <Route path="/editTimeRatings"
                           render={(props) => <EditTimeRatingsPage {...props}
                                                                   redirectTo={this.state.pagePath}
                                                                   pagePath={this.reportCurrentPagePath}
                                                                   onSessionChange={this.handleSessionChange}
                                                                   session={this.state.session}/>}/>

                    <Route path="/editCantPlayDates"
                           render={(props) => <EditCantPlayDatesPage {...props}
                                                                     redirectTo={this.state.pagePath}
                                                                     pagePath={this.reportCurrentPagePath}
                                                                     onSessionChange={this.handleSessionChange}
                                                                     session={this.state.session}/>}/>

                    <Route path="/changePassword"
                           render={(props) => <ChangePasswordPage {...props}
                                                                  redirectTo={this.state.pagePath}
                                                                  pagePath={this.reportCurrentPagePath}
                                                                  onSessionChange={this.handleSessionChange}
                                                                  session={this.state.session}/>}/>
                    <Route path="/newAccount"
                           render={(props) => <NewAccountPage {...props}
                                                              redirectTo={this.state.pagePath}
                                                              pagePath={this.reportCurrentPagePath}
                                                              disableHeader={true}
                                                              onSessionChange={this.handleSessionChange}/>}/>
                    <Route path="/verifyMail/:linkId"
                           render={(props) => <VerifyMailPage {...props}
                                                              onSessionChange={this.handleSessionChange}/>}/>
                    <Route path="/invite/:linkId"
                           render={(props) => <InvitePage {...props}
                                                          disableHeader={true}
                                                          pagePath={this.reportCurrentPagePath}
                                                          onSessionChange={this.handleSessionChange}
                                                          session={this.state.session}/>}/>

                    <Route path="/gruppspel/:groupplayId/signup"
                           render={(props) => <SignupPage {...props}
                                                          pagePath={this.reportCurrentPagePath}
                                                          onSessionChange={this.handleSessionChange}
                                                          session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/signupanswer/:answer"
                           render={(props) => <SignupPage {...props}
                                                              pagePath={this.reportCurrentPagePath}
                                                              onSessionChange={this.handleSessionChange}
                                                              session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/signuplink/:linkId/:answer"
                           render={(props) => <SignupLinkPage {...props}
                                                              pagePath={this.reportCurrentPagePath}
                                                              onSessionChange={this.handleSessionChange}
                                                              session={this.state.session}/>}/>
                    <Route path="/forgotPassword"
                           render={(props) => <ForgotPasswordPage {...props}
                                                                  redirectTo={this.state.pagePath}
                                                                  pagePath={this.reportCurrentPagePath}
                                                                  disableHeader={true}
                                                                  onSessionChange={this.handleSessionChange}/>}/>
                    <Route path="/resetPassword/:linkId"
                           render={(props) => <ResetPasswordPage {...props}
                                                                 pagePath={this.reportCurrentPagePath}
                                                                 disableHeader={true}
                                                                 onSessionChange={this.handleSessionChange}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/players"
                           render={(props) => <AdminPlayersPage {...props}
                                                                pagePath={this.reportCurrentPagePath}
                                                                onSessionChange={this.handleSessionChange}
                                                                session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/addPlayer"
                           render={(props) => <AddPlayerPage {...props}
                                                             pagePath={this.reportCurrentPagePath}
                                                             onSessionChange={this.handleSessionChange}
                                                             session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/sendSignups"
                           render={(props) => <SendSignupsPage {...props}
                                                             pagePath={this.reportCurrentPagePath}
                                                             onSessionChange={this.handleSessionChange}
                                                             session={this.state.session}/>}/>

                    <Route path="/gruppspel/:groupplayId/admin/bookings"
                           render={(props) => <AdminBookingsPage {...props}
                                                                 pagePath={this.reportCurrentPagePath}
                                                                 onSessionChange={this.handleSessionChange}
                                                                 session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/newSchedule"
                           render={(props) => <AdminNewSchedulePage {...props}
                                                                    pagePath={this.reportCurrentPagePath}
                                                                    onSessionChange={this.handleSessionChange}
                                                                    session={this.state.session}/>}/>

                    <Route path="/gruppspel/:groupplayId/admin/ongoingRound"
                           render={(props) => <OngoingPage {...props}
                                                            pagePath={this.reportCurrentPagePath}
                                                            onSessionChange={this.handleSessionChange}
                                                            session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/removePlayerOngoingRound"
                           render={(props) => <RemovePlayerPage {...props}
                                                           pagePath={this.reportCurrentPagePath}
                                                           onSessionChange={this.handleSessionChange}
                                                           session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/settings"
                           render={(props) => <SettingsPage {...props}
                                                            pagePath={this.reportCurrentPagePath}
                                                            onSessionChange={this.handleSessionChange}
                                                            session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/bookingSystemSettings"
                           render={(props) => <BookingsSystemSettingsPage {...props}
                                                                          pagePath={this.reportCurrentPagePath}
                                                                          onSessionChange={this.handleSessionChange}
                                                                          session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/bookingSettings"
                           render={(props) => <BookingSettingsPage {...props}
                                                                   pagePath={this.reportCurrentPagePath}
                                                                   onSessionChange={this.handleSessionChange}
                                                                   session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/rulesText"
                           render={(props) => <RulesTextConfigPage {...props}
                                                                   pagePath={this.reportCurrentPagePath}
                                                                   onSessionChange={this.handleSessionChange}
                                                                   session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/rules"
                           render={(props) => <RulesConfigPage {...props}
                                                               pagePath={this.reportCurrentPagePath}
                                                               onSessionChange={this.handleSessionChange}
                                                               session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/admin/groupplaySettings"
                           render={(props) => <GroupplaySettingsPage {...props}
                                                                   pagePath={this.reportCurrentPagePath}
                                                                   onSessionChange={this.handleSessionChange}
                                                                   session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/schema"
                           render={(props) => <SchedulePage {...props}
                                                            pagePath={this.reportCurrentPagePath}
                                                            onSessionChange={this.handleSessionChange}
                                                            session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/table"
                           render={(props) => <StandingsPage {...props}
                                                             pagePath={this.reportCurrentPagePath}
                                                             onSessionChange={this.handleSessionChange}
                                                             session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/match/:matchId"
                           render={(props) => <MatchPage {...props}
                                                         pagePath={this.reportCurrentPagePath}
                                                         onSessionChange={this.handleSessionChange}
                                                         session={this.state.session}/>}/>

                    <Route path="/gruppspel/:groupplayId/result/:matchId"
                           render={(props) => <ReportResultPage {...props}
                                                                pagePath={this.reportCurrentPagePath}
                                                                onSessionChange={this.handleSessionChange}
                                                                session={this.state.session}/>}/>

                    <Route path="/gruppspel/:groupplayId/contacts"
                           render={(props) => <ContactsPage {...props}
                                                            pagePath={this.reportCurrentPagePath}
                                                            onSessionChange={this.handleSessionChange}
                                                            session={this.state.session}/>}/>

                    <Route path="/gruppspel/:groupplayId/history/table/:roundNr"
                           render={(props) => <HistoryStandingsPage {...props}
                                                                    pagePath={this.reportCurrentPagePath}
                                                                    onSessionChange={this.handleSessionChange}
                                                                    session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/history/schedule/:roundNr"
                           render={(props) => <HistorySchedulePage {...props}
                                                                   pagePath={this.reportCurrentPagePath}
                                                                   onSessionChange={this.handleSessionChange}
                                                                   session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/history/billing/:roundNr"
                           render={(props) => <HistoryBillingPage {...props}
                                                                   pagePath={this.reportCurrentPagePath}
                                                                   onSessionChange={this.handleSessionChange}
                                                                   session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/history"
                           render={(props) => <HistoryPage {...props}
                                                           pagePath={this.reportCurrentPagePath}
                                                           onSessionChange={this.handleSessionChange}
                                                           session={this.state.session}/>}/>
                    <Route path="/gruppspel/:groupplayId/rules"
                           render={(props) => <RulesPage {...props}
                                                           pagePath={this.reportCurrentPagePath}
                                                           onSessionChange={this.handleSessionChange}
                                                           session={this.state.session}/>}/>
                    { /* needs to be last, default page */ }
                    <Route path="/gruppspel/:groupplayId"
                           render={(props) => <StandingsPage {...props}
                                                             pagePath={this.reportCurrentPagePath}
                                                             onSessionChange={this.handleSessionChange}
                                                             session={this.state.session}/>}/>
                    <Route path="/test"
                           render={(props) => <TestPage {...props}
                                                        pagePath={this.reportCurrentPagePath}
                                                        onSessionChange={this.handleSessionChange}
                                                        session={this.state.session}/>}/>
                </Switch>
            </HashRouter>
        );
    }
}

export default App;
