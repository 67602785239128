import * as React from "react"
import {CSSProperties} from "react"
import {mobile} from "../App"

interface Props {
    bold?: boolean
    strikeOut?: boolean
    left?: string
    mobLeft?: string
    right?: string
    mobRight?: string
    secondRow?: boolean
    mobFirstRow?: boolean
    mobSecondRow?: boolean
    mobThirdRow?: boolean
    mobFourthRow?: boolean
    color?: string
    bgColor?: string
}

export default class TableText extends React.Component<Props> {
    render() {
        const top = mobile ?
            (this.props.mobFirstRow ? "0.5rem" :
                (this.props.mobSecondRow ? "3rem" :
                    (this.props.mobThirdRow ? "5.5rem" :
                        (this.props.mobFourthRow ? "8rem" : "1.75rem"))))
            : (this.props.secondRow ? "2rem" : "0.4rem");
        const baseCss: CSSProperties = {position: "absolute", top: top};
        const leftCss: CSSProperties = mobile ?
            this.props.mobLeft ? {left: this.props.mobLeft} : {}
            :
            this.props.left ? {left: this.props.left} : {};
        const rightCss: CSSProperties = mobile ?
            this.props.mobRight ? {right: this.props.mobRight} : {}
            :
            this.props.right ? {right: this.props.right} : {};
        const colorCss: CSSProperties = this.props.color ? {color: this.props.color} : {};
        const bgColorCss: CSSProperties = this.props.bgColor ? {backgroundColor: this.props.bgColor} : {};
        const boldCss: CSSProperties = this.props.bold ? {fontWeight: "bold"} : {};
        const strikeThroughCss: CSSProperties = this.props.strikeOut ? {textDecoration:"line-through"} : {}
        const css = {...baseCss, ...leftCss, ...rightCss, ...colorCss, ...bgColorCss, ...boldCss, ...strikeThroughCss};

        return (
            <div style={css}>
                {this.props.children}
            </div>
        );
    }
}