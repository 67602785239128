import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../../BasePage";
import {getStandings, removePlayerOngoingRound} from "../../../api/api";
import {AccountLightDto, PlayerStandingDto, RoundStandingsDto} from "../../../api/dtos";
import FullPageMessage from "../../../components/FullPageMessage";
import Table from "../../../components/Table";
import TableRow from "../../../components/TableRow";
import TableText from "../../../components/TableText";
import TableButton, {ButtonType} from "../../../components/TableButton";
import Modal from "../../../components/Modal";
import {mobile} from "../../../App";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    loading: boolean
    fatalError: boolean
    standings?: RoundStandingsDto
    confirmPlayer?: AccountLightDto
    confirmGroup?: number
    redirectTo?: string
}

export default class RemovePlayerPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        const {groupplayId} = this.props.match.params;
        try {
            const session = this.props.session;
            const sessionId = session ? session.sessionId : undefined;
            const standingsDto = await getStandings(groupplayId, sessionId);
            this.setState({standings: standingsDto});
        } catch (error: any) {
            switch (error.type) {
                case "NOT_FOUND":
                    //just ignore..
                    break;
                default:
                    console.log('ERROR' + error);
                    this.setState({fatalError: true});
                    break;
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {groupplayId} = this.props.match.params;

        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>)
        }

        return (
            <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                      adminPage={true}>
                {!this.state.standings &&
                    <FullPageMessage text={"Det finns inget spelschema än.."}/>}

                {this.state.confirmPlayer && this.state.confirmGroup &&
                    <Modal  height={mobile ? "25rem" : "10rem"}
                            onClose={this.removeCanceled}
                            onConfirm={() => this.removeConfirmed(groupplayId)}>
                        Vill du ta bort <b>{this.state.confirmPlayer.firstName} {this.state.confirmPlayer.lastName}</b> från
                        grupp {this.state.confirmGroup} i pågående omgång? <br/> <br/>
                        (Poäng kommer inte räknas från eventuellt spelade matcher och alla
                        framtida matcher bokas av)<br/><br/>
                    </Modal>
                }

                {this.state.standings &&
                    <Table>
                        {this.state.standings.groups.map((g, i1) => {
                            const lastRow1: boolean = this.state.standings?.groups.length === (i1 + 1);
                            return g.players.map((p, i2) => {
                                const lastRow2: boolean = g.players.length === (i2 + 1);
                                return this.getPlayer(p, g.groupNr, lastRow1 && lastRow2);
                            })
                        })}
                    </Table>}
            </BasePage>
        );
    }

    getPlayer = (p: PlayerStandingDto, groupNr: number, lastRow: boolean) => {
        const color = p.playerQuit ? "var(--buttonDisabled)" : ""
        return (
            <TableRow key={p.player.id} width="25rem" lastRow={lastRow}>
                <TableText left="0" mobLeft="0" color={color}>{groupNr}</TableText>
                <TableText left="1rem" mobLeft="2rem" color={color} strikeOut={p.playerQuit}>{p.player.firstName + " " + p.player.lastName}</TableText>
                <TableText right="6rem" mobRight="10rem" color={color} strikeOut={p.playerQuit}>{p.points / 10}p</TableText>
                {!p.playerQuit &&
                    <TableButton type={ButtonType.REMOVE} right="0" mobRight="-0.6rem"
                             onClick={() => this.removePlayerClicked(p.player, groupNr)}/>}
            </TableRow>
        );
    }

    private removePlayerClicked = (player: AccountLightDto, group: number) => {
        this.setState({confirmPlayer: player, confirmGroup: group})
    }

    private removeConfirmed = async (groupplayId: string) => {
        if(this.state.confirmPlayer && this.props.session) {
            this.setState({loading: true})
            console.log("confirmed:" + groupplayId + ", accountid:" + this.state.confirmPlayer.id)
            await removePlayerOngoingRound(groupplayId, this.state.confirmPlayer.id, this.props.session.sessionId)
            this.setState({redirectTo: "/gruppspel/" + groupplayId + "/table", confirmPlayer: undefined, confirmGroup: undefined, loading: false})
        }
    }

    private removeCanceled = () => {
        this.setState({confirmPlayer: undefined, confirmGroup: undefined})
    }

}
